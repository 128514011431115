import {toast} from "react-toastify";

export const reset = (state = {}, action) => {
  switch (action.type) {
    case "RESET_PASSWORD": {
        toast.success('Password reset email was sent')
        return { ...state, reset: action.reset }
    }
    default: {
      return state
    }
  }
}
 