const initialState = {
  authError: ''
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_LOGIN":
      return { ...state, authError:''};
    case "NOTIFY_VALIDATION_AUTH_ERROR":
      return { ...state, authError: action.payload.authError };
    default:
      return state
  }
};

export default notificationReducer
