import { createStore, applyMiddleware, compose } from "redux"
import createDebounce from "redux-debounced"
import thunk from "redux-thunk"
import rootReducer from "../reducers/rootReducer"
import * as Sentry from "@sentry/react";

const middlewares = [thunk, createDebounce()]

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer)
)

export { store }
