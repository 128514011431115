import $ from 'jquery';

window.intercomSettings = {
  app_id: "azwrkbg3"
};
let defaultIntercomSettings = {
  app_id: "azwrkbg3"
};
function disableIntercom(){
    $("#intercom-frame").hide()
    $(".intercom-lightweight-app").hide()
}
function enableIntercom(){
    $("#intercom-frame").show()
    $(".intercom-lightweight-app").show()
}
function initIntercom(){
    console.log('initIntercom')
    var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/azwrkbg3';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};l();}
}
window.initIntercom = initIntercom;
export {disableIntercom, initIntercom, enableIntercom, defaultIntercomSettings};
