import {toast} from "react-toastify";

const initialState = {
    problematic_products_paginate:[],
    problematic_products_total:0,
    products_on_check_paginate:[],
    products_on_check_total:0,
};


const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PROBLEMATIC_PRODUCTS_PAGINATE":
      return {...state, problematic_products_paginate: action.data, problematic_products_total:action.total};
    case "GET_PRODUCTS_ON_CHECK_PAGINATE":
      return {...state, products_on_check_paginate: action.data, products_on_check_total:action.total};
    case "ISSUE_SOLVED":
      toast.success('Issue Solved!')
      return state;
    default:
      return state
  }
};

export default clientReducer
