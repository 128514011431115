import {toast} from "react-toastify";

const initialState = {
    selected_rows: {},
    orders_paginate:[],
    orders_paginate_total:0,
};


const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CLEANER_ORDERS_PAGINATE":
      return {...state, orders_paginate: action.orders, orders_paginate_total:action.total};
    case "TRANSFER_CLEANER_ORDER":
      if(action.payload.status){
        toast.success('Order Transferred Successful!');
      }else{
        toast.error('Order Transferred Error!');
      }
      return state;
    case "COMPLETE_CLEANER_ORDER":
      if(action.payload.status){
        toast.success('Order Completed Successful!');
      }else{
        toast.error('Order Completed Error!');
      }
      return state;
    case "SELECT_CLEANER_ORDER":
      let selectedRows = {};
      if(action.check){
        selectedRows = Object.assign({}, action.row, state.selected_rows);
        console.log(selectedRows)
      } else {
        console.log(selectedRows, 'false', action.row)

        selectedRows = {...state.selected_rows};
        let key = Object.keys(action.row)[0];
        console.log('key', key)
        delete selectedRows[key];
        console.log(selectedRows)

      }
        return {...state, selected_rows: selectedRows}
    case "RESET_SELECT_CLEANER_ORDER":
      return {...state, selected_rows: {}}
    default:
      return state
  }
};

export default clientReducer
