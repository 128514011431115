import { toast } from "react-toastify";
import { ACTIONS } from "./../../actions/reviews/index"

const initialState = {
    data      : [],
    total     : 0,
    fetching  : false,
    data_saved: null,
    creating_data: null,
    confirm_review_data: null,
};


const reviewsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_REVIEWS_CAMPAIGNS:
            return {...state, data: action.payload.data, total: action.payload.total};
        case ACTIONS.GET_REVIEWS_CAMPAIGNS:
            toast.success('Order Approved!')
            return state;
        case ACTIONS.UPDATE_REVIEW_FOR_CAMPAIGN:
            let result = null;
            if (action.payload.status === 'error') {
                let errors = '';
                for(let key in action.payload.errors){
                    let field = action.payload.errors[key];
                    let text = `${key.split('_').join(' ')}: `;
                    field.map((el, i) => {
                        text += `${el}`;
                    })
                    errors += text;
                }
                toast.error(errors);
                result = false;
            } else if (action.payload.status == true) {
                toast.success('Success updated!');
                result = true;
            }
            return {...state, data_saved: result};
        case ACTIONS.REVIEW_FOR_CAMPAIGN_SAVED:
            return {...state, data_saved: action.payload};
        case ACTIONS.CONFIRM_REVIEW_CREATING:
            return {...state, confirm_review_data: action.payload?.job};

        case ACTIONS.CREATE_REVIEW_FOR_CAMPAIGN:
            if(action.payload?.id){
                return {...state, creating_data: action.payload.id};
            }
            if (action.payload.status === 'error'){
                toast.warn(action.payload.message);
            }
            return {...state, creating_data: null};
        case ACTIONS.RESET_REVIEW_CREATING:
            return {...state, fetching  : false, data_saved: null, creating_data: null, confirm_review_data: null};
        default:
            return state
    }
};

export default reviewsReducer
