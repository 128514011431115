import {toast} from "react-toastify";

const initialState = {
    clients: [],
    users_paginate:[],
    users_paginate_total:0,
    selectedClient: null,
    client: {
        id: -1,
        click_rate: 0
    },
    notifications: []
};


const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USERS_PAGINATE":
      return {...state, users_paginate: action.clients, users_paginate_total:action.total};
    case "EDIT_USER_ROLE":
        toast.success('Role updated');
      return state;
    case "EDIT_USER_CHECKED":
        toast.success('Updated');
      return state;
    default:
      return state
  }
};

export default clientReducer
