import {initIntercom, disableIntercom} from "@components/custom/intercom"; 
import moment from "moment";
const initialState = {
    users_list:[],
    user: {
        id: 0,
        email: "",
        name: "",
        photoUrl: "",
        role: {
            id: 0,
            name: "",
            description: ""
        }
    }
}

const authorizedUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_USERS":
            return {...state, users_list: action.users};
        case "FETCH_AUTHORIZED_USER_INFO":
            console.log(action.user.role.name)
            if(action.user.role.name === "clientManager"){
                window.intercomSettings.name = action.user.name;
                window.intercomSettings.email = action.user.email;
                window.intercomSettings.company_name = action.user.client.name
                window.intercomSettings.created_at = moment(action.user.created_at).unix()
                initIntercom();
            }else{
                disableIntercom()
            }
            return {...state, user: action.user};
        default:
            return state
    }
};

export default authorizedUserReducer
