import React from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"
import axios from "axios"
import * as Icon from "react-feather"
import {connect} from "react-redux";
import { history } from "@src/history"
import { logoutWithJWT }  from '@store/actions/auth/loginActions';
import "./custom.scss";

const handleNavigation = (e, path) => {
  e.preventDefault();
  history.push(path)
}

const UserDropdown = props => {
  return (
    <DropdownMenu right>
      { props.user.role.id !== 2 ? (
        <DropdownItem
          tag="a"
          href="#"
          onClick={e => handleNavigation(e, "/settings")}
        >
          <Icon.User size={14} className="mr-50" />
          <span className="align-middle">Edit Profile</span>
        </DropdownItem>

        ) : null }

      <DropdownItem divider />
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => {
          e.preventDefault();
          props.logoutWithJWT();
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.user.name}
              </span>
              <span className="user-status">{''}</span>
            </div>
            <span data-tour="user">
              <img
                src={this.props.user.photoUrl ? this.props.user.photoUrl : '/user-icon.png' }
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.authorizedUser.user
  }
}

export default connect(mapStateToProps, {logoutWithJWT})(NavbarUser)
