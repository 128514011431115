import axios from "axios"

export const ACTIONS = {
    GET_REVIEWS_CAMPAIGN: 'GET_REVIEWS_CAMPAIGN',
    GET_REVIEWS_CAMPAIGNS: 'GET_REVIEWS_CAMPAIGNS',
    UPDATE_REVIEW_FOR_CAMPAIGN: 'SAVE_REVIEW_FOR_CAMPAIGN',
    REVIEW_FOR_CAMPAIGN_SAVED: 'REVIEW_FOR_CAMPAIGN_SAVED',
    CREATE_REVIEW_FOR_CAMPAIGN: 'CREATE_REVIEW_FOR_CAMPAIGN',
    CONFIRM_REVIEW_CREATING: "CONFIRM_REVIEW_CREATING",
    FETCHING_REVIEWS: 'FETCHING_REVIEWS',
    RESET_REVIEW_CREATING: 'RESET_REVIEW_CREATING',
}

export const getReviewList = (options) => {

    let params = {};

    for(let option in options){
        let val = options[option];
        if(val != '' && val){
            params[option] = val;
        }
    }

    return dispatch => {
        dispatch(setFetch(true))
        axios.get(`/api/admin/campaigns/list`, {
            params: params
        })
            .then(response => {
                dispatch({
                    type: ACTIONS.GET_REVIEWS_CAMPAIGNS,
                    payload: response.data
                })
                setFetch(false)
            })
            .catch(err => {
                console.error(err);
            })
    }
}

export const setFetch = (fetch = true) => {
    return dispatch => {
        dispatch({
            type: ACTIONS.FETCHING_REVIEWS,
            payload: fetch
        })
    }
}

export const setDataSaved = (saved) => {
    return dispatch => {
        dispatch({
            type: ACTIONS.REVIEW_FOR_CAMPAIGN_SAVED,
            payload: saved
        })
    }
}

export const updateReview = (id, data = {}) => {
    return dispatch => {
        dispatch(setFetch(true))
        axios.put(`/api/admin/job/${id}/edit`, data)
             .then(response => {
                 dispatch({
                     type: ACTIONS.UPDATE_REVIEW_FOR_CAMPAIGN,
                     payload: response.data
                 })
                 setFetch(false)
             })
             .catch(err => {
                 console.error(err.response);

                 dispatch({
                     type: ACTIONS.UPDATE_REVIEW_FOR_CAMPAIGN,
                     payload: err.response.data
                 })
                 setFetch(false)

             })
    }
}

export const createReview = (data = {}) => {
    return dispatch => {
        dispatch(setFetch(true))
        axios.post(`/api/admin/job/add`, data)
             .then(response => {
                 dispatch({
                     type: ACTIONS.CREATE_REVIEW_FOR_CAMPAIGN,
                     payload: response.data
                 })
                 setFetch(false)
             })
             .catch(err => {
                 console.error(err.response);

                 dispatch({
                     type: ACTIONS.CREATE_REVIEW_FOR_CAMPAIGN,
                     payload: err.response.data
                 })
                 setFetch(false)

             })
    }
}

export const confirmReview = (id) => {
    return dispatch => {
        dispatch(setFetch(true))
        axios.get(`/api/admin/job/${id}`)
             .then(response => {
                 dispatch({
                     type: ACTIONS.CONFIRM_REVIEW_CREATING,
                     payload: response.data
                 })
                 setFetch(false)
             })
             .catch(err => {
                 console.error(err.response);

                 dispatch({
                     type: ACTIONS.CONFIRM_REVIEW_CREATING,
                     payload: err.response.data
                 })
                 setFetch(false)

             })
    }
}

export const resetReviewCreating = () => {
    return dispatch => {
        dispatch({
            type: ACTIONS.RESET_REVIEW_CREATING
        })
    }
}
