import {FormFeedback} from "reactstrap";
import React from "react";
import {history} from "@src/history";

export function apiErrorHandler(err, require_authorized = false) {
    console.log(['err', err])
    if (err.response.status === 422 
            && err.response.data 
            && err.response.data.errors && err.response.data.errors.email) {
        return {
            type: "NOTIFY_VALIDATION_AUTH_ERROR",
            payload: {authError: err.response.data.errors.email[0]}
        };
    }
    
    if (err.response.status === 422 
            && err.response.data 
            && err.response.data.errors && err.response.data.errors.sms) {
        if(err.response.data.errors.sms[0] === 'verify sms'){
            return {
                type: "NOTIFY_VERIFY_SMS"
            };
        }else{
            return {
                type: "NOTIFY_VALIDATION_SMS_ERROR",
                payload: {authError: err.response.data.errors.sms[0]}
            };
        }
    }

    if (err.response.status === 401 && require_authorized) {
        history.push("/")
    }
    
    if (err.response.status === 429) {
        return {
            type: "NOTIFY_VALIDATION_AUTH_ERROR",
            payload: {authError: 'Too many attempts, please try in 2 minutes!'}
        };
    }

    return {
        type: "NOTIFY_VALIDATION_AUTH_ERROR",
        payload: {authError: ''}
    };
}

export function apiFormErrorHandler(cb) {
    return (err) => {
        if (err.response.status === 422) {
            if (cb !== undefined) {
                cb(err.response.data.errors);
            }
        }
    }
}

export function hasInputFormError(err, field) {
    return field in err;
}

export function showInputFormError(err, field) {
    if(!hasInputFormError(err, field)) {
        return '';
    }

    return  err[field].map(message => <FormFeedback>{message}</FormFeedback>);
}
