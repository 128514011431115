import {toast} from "react-toastify";

const initialState = {
    refunds_paginate:[],
    refunds_paginate_total:0,
};


const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DOWNLOAD_REFUND_INVOICE":
        const url = window.URL.createObjectURL(new Blob([action.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `refund_invoice.pdf`); 
        document.body.appendChild(link);
        link.click();
      return state;
    case "GET_REFUNDS_PAGINATE":
      return {...state, refunds_paginate: action.refunds, refunds_paginate_total:action.total};
    default:
      return state
  }
};

export default clientReducer
