import React from 'react';

export  const Monitor = (props) => {
  const {size=30, st0='#fff', st1='#b2eedf'} = props;
  let {width, height} = props;
  if(!width) width = size;
  if(!height) width = size;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width={width} height={height} viewBox="0 0 512 512">
      <path className="st0" fill={st0}
            d="M432.7 88.4H75.3c-2.8 0-5 2.2-5 5v249.2c0 2.8 2.2 5 5 5h357.3c2.8 0 5-2.2 5-5V93.4c.1-2.8-2.2-5-4.9-5zm-5 249.2H80.3V98.4h347.3l.1 239.2z"></path>
      <path className="st0" fill={st0}
            d="M327.2 400.6h-30v-57.8h-10.6l.1 57.8h-65.3l.2-54.6-10.6.1v54.5h-30c-2.8 0-5 2.2-5 5s2.2 5 5 5h146.3c2.8 0 5-2.2 5-5-.1-2.8-2.4-5-5.1-5z"></path>
      <path className="st1" fill={st1}
            d="M187 294.6h-46.6c-3.2 0-5.7-2.6-5.7-5.7v-52.7c0-3.2 2.6-5.7 5.7-5.7H187c3.2 0 5.7 2.6 5.7 5.7v52.7c0 3.2-2.6 5.7-5.7 5.7zM276.6 294.6H230c-3.2 0-5.7-2.6-5.7-5.7v-94.4c0-3.2 2.6-5.7 5.7-5.7h46.6c3.2 0 5.7 2.6 5.7 5.7v94.4c0 3.2-2.6 5.7-5.7 5.7zM366.2 294.6h-46.6c-3.2 0-5.7-2.6-5.7-5.7V147c0-3.2 2.6-5.7 5.7-5.7h46.6c3.2 0 5.7 2.6 5.7 5.7v142c0 3.1-2.6 5.6-5.7 5.6z"></path></svg>
  )
};

export const Speaker = (props) => {
  const {size=30, st0='#fff', st1='#b2eedf'} = props;
  let {width, height} = props;
  if(!width) width = size;
  if(!height) width = size;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 512 512">
      <path fill="none" d="M402.1 281.3v-83.6z"></path>
      <path className="st0" fill={st0} d="M402.1 197.7v-83.5c0-5.2-2.5-10-6.7-12.9-4.2-2.9-9.6-3.6-14.5-1.9l-160.2 59H104.1c-8.7 0-15.8 7.1-15.8 15.8v4.4h-4.4c-11.2 0-20.3 9.1-20.3 20.3V280c0 11.2 9.1 20.3 20.3 20.3h4.4v4.5c0 8.7 7.1 15.8 15.8 15.8h21.4l44 90.1c.8 1.7 2.6 2.8 4.5 2.8h34c1.6 0 3.2-.8 4.1-2.1.9-1.3 1.2-3 .6-4.6l-31-86.3h39l160.2 59c1.8.7 3.6 1 5.4 1 3.2 0 6.3-1 9-2.8 4.2-2.9 6.7-7.8 6.7-12.9v-83.5l.1-83.6zM200.9 403.5h-23.8l-40.5-83h34.5l29.8 83zm191.2-211.1V364.5c0 1.9-.8 3.7-2.3 4.8-1.6 1.2-3.6 1.5-5.5.8L226.5 312v-8.3c0-2.6-1.9-4.9-4.5-5.2-3-.3-5.5 2.1-5.5 5v7H104c-3.2 0-5.8-2.6-5.8-5.8v-5.4c0-5-4.1-9.1-9.1-9.1H83.8c-5.7 0-10.3-4.6-10.3-10.3v-81.2c0-5.7 4.6-10.3 10.3-10.3H89.1c5 0 9.1-4.1 9.1-9.1V174c0-3.2 2.6-5.8 5.8-5.8h112.5v135.1c0 2.6 1.9 4.9 4.5 5.2 3 .3 5.5-2.1 5.5-5V166.9l157.8-58.1c1.9-.7 3.9-.4 5.5.8 1.5 1.1 2.3 2.9 2.3 4.8v78z" ></path>
      <path className="st1" d="M402.1 187.6V291.4c25.9-2.5 46.2-24.4 46.2-51v-1.8c0-26.5-20.3-48.4-46.2-51z" fill={st1}></path>
    </svg>
  )
}
export const Billing = (props) => {
  const {size=30, st0='#fff', st1='#b2eedf'} = props;
  let {width, height} = props;
  if(!width) width = size;
  if(!height) width = size;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width={width} height={height} viewBox="0 0 512 512">
  <path className="st0" fill={st0}
        d="M346.4 127.4c-2.9 0-5.3 2.5-5 5.5.2 2.6 2.6 4.5 5.2 4.5h42.3V189H123.2v-51.6h43c2.6 0 4.9-1.9 5.2-4.5.3-3-2.1-5.5-5-5.5h-47.9c-2.9 0-5.3 2.4-5.3 5.3v274.5c0 2.9 2.4 5.3 5.3 5.3h275.2c3 0 5.3-2.4 5.3-5.4l-.1-8.7V132.7c0-2.9-2.4-5.3-5.3-5.3h-47.2M123.2 402.5V199h265.7v203.5H123.2z"></path>
      <path className="st0" fill={st0} d="M190 137.4h132.4c2.8 0 5-2.2 5-5s-2.2-5-5-5H190c-2.8 0-5 2.2-5 5s2.2 5 5 5z"></path>
      <path className="st0" fill={st0}
            d="M178.2 166.5c8.3 0 15-6.7 15-15v-38.1c0-8.3-6.7-15-15-15s-15 6.7-15 15v38.1c0 8.2 6.8 15 15 15zm-5-53.2c0-2.8 2.2-5 5-5s5 2.2 5 5v38.1c0 2.8-2.2 5-5 5s-5-2.2-5-5v-38.1zM333.8 166.5c8.3 0 15-6.7 15-15v-38.1c0-8.3-6.7-15-15-15s-15 6.7-15 15v38.1c-.1 8.2 6.7 15 15 15zm-5.1-53.2c0-2.8 2.2-5 5-5s5 2.2 5 5v38.1c0 2.8-2.2 5-5 5s-5-2.2-5-5v-38.1z"></path>
      <path
        className="st1" d="M257 386.5c-7.5 0-13.5-6.1-13.5-13.5v-3c-9.2-1.9-16.8-5.9-22.6-11.9-5-5.2-8.5-11.7-10.2-19.2-1-4.3 0-8.7 2.7-12.1 2.7-3.4 6.8-5.4 11.2-5.4 6.7 0 12.4 4.5 13.9 10.9.7 3 2 5.3 3.9 7.2 3.1 3 7.7 4.5 14.1 4.5 5.4 0 9.6-1.3 12.7-3.9 2.7-2.3 3.9-5.1 3.9-8.9 0-3.5-1-6-3-8-2-1.9-6.6-5-17.1-8.7-13.7-4.3-23.4-9.6-29.5-16.2-6.6-7.1-9.9-16.1-9.9-26.9 0-11.3 3.6-20.8 10.8-28.1 5.2-5.2 11.7-8.9 19.4-10.8v-4.3c0-7.5 6.1-13.6 13.6-13.6s13.6 6.1 13.6 13.6v4.4c8.1 2.2 14.8 6.4 19.9 12.5 4.5 5.4 7.5 12 9 19.7.8 4.2-.3 8.4-3 11.7-2.7 3.3-6.8 5.2-11 5.2-6.7 0-12.6-4.7-14-11.3-.7-3.2-1.9-5.7-3.7-7.8-2.6-2.9-6.1-4.3-11-4.3-5.3 0-9 1.1-11.5 3.5-2.4 2.2-3.5 5.2-3.5 9.3 0 5.1 1.7 7.2 2.9 8.3 2 1.9 6.6 4.9 16.8 8.3 10.4 3.5 17.9 6.9 23 10.4 5.5 3.8 9.8 8.5 12.7 13.8 2.9 5.4 4.3 11.7 4.3 18.9 0 11.5-3.8 21-11.1 28.2-5.3 5.2-12 8.8-20 10.6v3.3c-.3 7.6-6.3 13.6-13.8 13.6z"
        fill={st1}></path>
  </svg>
  )
}

export const Exit = (props) => {
  const {size=30, st0='#fff', st1='#b2eedf'} = props;
  let {width, height} = props;
  if(!width) width = size;
  if(!height) width = size;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width={width} height={height} viewBox="0 0 512 512" >
    <path className="st0" fill={st0} d="M455 89.8H252.4c-4.6 0-8.3 3.7-8.3 8.3V416c0 4.6 3.7 8.3 8.3 8.3H455c4.6 0 8.3-3.7 8.3-8.3V98.1c.1-4.6-3.7-8.3-8.3-8.3zm-8.3 317.9h-186V106.4h186.1v301.3z"></path>
    <path className="st0" fill={st0} d="M407.9 268.6c6.6 0 11.9-5.3 11.9-11.9 0-6.6-5.3-11.9-11.9-11.9s-11.9 5.3-11.9 11.9c0 6.6 5.3 11.9 11.9 11.9z"></path>
    <path className="st1" d="M218.9 257.7L148.2 187c-4.4-4.4-11.8-1.3-11.8 4.9v31.4H55.6c-3.8 0-6.9 3.1-6.9 6.9v64.7c0 3.8 3.1 6.9 6.9 6.9h80.7v31.4c0 6.2 7.5 9.3 11.8 4.9l70.7-70.7c2.8-2.6 2.8-7 .1-9.7z" fill={st1}></path>
    </svg>
  )
}


export const Settings = (props) => {
  const {size=30} = props;
  let {width, height} = props;
  if(!width) width = size;
  if(!height) width = size;
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="strokeFill" d="M9 14H32" stroke="white" strokeLinecap="round"/>
        <path className="strokeFill" d="M9 20H32" stroke="white" strokeLinecap="round"/>
        <path className="strokeFill" d="M9 26H32" stroke="white" strokeLinecap="round"/>
        <circle className="strokeAndFill" cx="16" cy="14" r="2.4" fill="#B2EEDF" stroke="white" strokeWidth="1.2"/>
        <circle className="strokeAndFill" cx="24" cy="20" r="2.4" fill="#B2EEDF" stroke="white" strokeWidth="1.2"/>
        <circle className="strokeAndFill" cx="16" cy="26" r="2.4" fill="#B2EEDF" stroke="white" strokeWidth="1.2"/>
    </svg>



  )
}

export const EBook = (props) => {
  const {size=30} = props;
  let {width, height} = props;
  if(!width) width = size;
  if(!height) width = size;
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="strokeFill" d="M30 10.5H9.5V16.7727H30C30.8284 16.7727 31.5 16.1012 31.5 15.2727V12C31.5 11.1716 30.8284 10.5 30 10.5Z" stroke="white"/>
        <path className="strokeFill" d="M30 16.8636H9.5V23.1364H30C30.8284 23.1364 31.5 22.4648 31.5 21.6364V18.3636C31.5 17.5352 30.8284 16.8636 30 16.8636Z" stroke="white"/>
        <path className="strokeFill" d="M30 23.2273H9.5V29.5H30C30.8284 29.5 31.5 28.8284 31.5 28V24.7273C31.5 23.8988 30.8284 23.2273 30 23.2273Z" stroke="white"/>
        <path d="M27 17H21V26.1866C21 26.284 21.1251 26.3239 21.1815 26.2445L24 22.2778L26.8185 26.2445C26.8749 26.3239 27 26.284 27 26.1866V17Z" fill="#B2EEDF"/>
    </svg>
  )
}

export const LogOut = (props) => {
  const {size=30} = props;
  let {width, height} = props;
  if(!width) width = size;
  if(!height) width = size;
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30.5411 11H19.299C19.0439 11 18.8387 11.2101 18.8387 11.4714V29.5285C18.8387 29.7898 19.0439 30 19.299 30H30.5411C30.7962 30 31.0015 29.7898 31.0015 29.5285V11.4714C31.0015 11.2101 30.7907 11 30.5411 11ZM30.0752 29.0571H19.7594V11.9429H30.0752V29.0571Z" fill="white"/>
        <path d="M27.9232 21.162C28.2893 21.162 28.5832 20.861 28.5832 20.4861C28.5832 20.1112 28.2893 19.8102 27.9232 19.8102C27.5572 19.8102 27.2632 20.1112 27.2632 20.4861C27.2632 20.861 27.5627 21.162 27.9232 21.162Z" fill="white"/>
        <path d="M17.4411 20.5367L13.52 16.5265C13.2759 16.2766 12.8655 16.4527 12.8655 16.8048V18.5884H8.38421C8.17346 18.5884 8.00153 18.7645 8.00153 18.9803V22.6554C8.00153 22.8712 8.17346 23.0473 8.38421 23.0473H12.86V24.8309C12.86 25.183 13.2759 25.3591 13.5144 25.1092L17.4356 21.0933C17.5909 20.94 17.5909 20.69 17.4411 20.5367Z" fill="#B2EEDF"/>
    </svg>

  )
}
